import React, { useState } from 'react';

const EthanolCalculator = () => {
  // state
  const [ethanolBlend, setEthanolBlend] = useState(10);
  const [tankCapacity, setTankCapacity] = useState(45);

  // methods
  const updateInputValue = (e: any) => {
    switch (e.target.name) {
      case 'ethanol-blend':
        setEthanolBlend(parseInt(e.target.value));

        break;
      case 'tank-capacity':
        setTankCapacity(e.target.value);

        break;
      default:
        return;
    }
  };
  const calculateNeededEthanol = (tank: number, ethanol: number) => Math.round((tank * (ethanol / 100) * 10)) / 10;
  const calculateNeededFuel = (tank: number, ethanol: number) => Math.round((tank - ethanol) * 10) / 10;

  // render
  const options = [...Array(18).keys()].map((key) => {
    const blend: number = (key + 1) * 5;

    return <option key={blend} value={blend}>{`E${blend}`}</option>;
  });
  let neededEthanol: number = 0;
  let neededFuel: number = 0;

  if (tankCapacity) {
    neededEthanol = calculateNeededEthanol(tankCapacity, ethanolBlend);
    neededFuel = calculateNeededFuel(tankCapacity, neededEthanol);
  }

  return (
    <div className="ethanol-calculator">
      <div className="calculator-result">
        <div className="fuel">
          <span className="value">{ neededFuel || '-' }</span>
          <span>Fuel</span>
        </div>
        <div className="ethanol">
          <span className="value">{ neededEthanol || '-'}</span>
          <span>Ethanol</span>
        </div>
      </div>

      <select className="ethanol-blend" name="ethanol-blend" value={ethanolBlend} onChange={updateInputValue}>
        { options }
      </select>

      <label className="tank-capacity" htmlFor="tank-capacity">
        Fuel Tank Capacity
        <input type="text" name="tank-capacity" value={tankCapacity} onChange={updateInputValue} />
      </label>
    </div>
  );
};

export default EthanolCalculator;
