import React from 'react';
import logo from './potenza.png';

import EthanolCalculator from './components/EthanolCalculator';

import './App.css';

function App() {
  return (
    <div className="App">
      <main className="main">
        <div className="center">
          <img src={logo} className="logo" alt="logo" />

          <EthanolCalculator />
        </div>
      </main>
    </div>
  );
}

export default App;
